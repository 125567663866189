import { memo } from "react";

export const VitaForCorporate = memo(function VitaForCorporateComponent() {
  return (
    <div className="h-[550px] md:h-auto md:pb-[40px] bg-gray-30 sm:bg-[transparent] overflow-hidden">
      <div className="container h-full relative">
        <div className="mt-[100px] lg:mt-[60px] sm:mt-[16px] relative z-10">
          <span className="font-mzeqala text-raspberry-100 text-[20px]">
            ვიტას შესახებ
          </span>
          <h2 className="mt-0 mb-[18px] md:mb-2 font-bold text-sloganSize lg:text-sloganLgSize md:text-sloganMdSize text-blackPrimary-100 leading-[50px] lg:leading-[34px] md:leading-[24px]">
            ვიტა ორგანიზაციისთვის
          </h2>
          <p className="font-medium text-blackPrimary-60 text-[20px] lg:text-md md:text-rg leading-[30px] md:leading-[24px]">
            რატომ უნდა აგვირჩიოთ ჩვენ, გაიგეთ მეტი ჩვენ შესახებ
          </p>
        </div>
        <div className="grid grid-cols-3 lg:grid-cols-1 gap-[30px] relative z-10 mt-[42px] md:mt-[32px]">
          {[
            "ჯანმრთელი და პროდუქტიული სამუშაო გუნდი",
            "Employer Branding-სასურველი დამსაქმებლის რეპუტაციის გამყარება",
            "ნაკლები დანახარჯი ბაზარზე არსებული სხვა კორპორატიულ ბენეფიტებთან შედარებით",
          ].map((_, index) => (
            <div className="flex items-center gap-5 md:gap-3" key={index}>
              <div
                className={`${index == 0 && "bg-blue-100"} ${
                  index == 1 && "bg-yellow"
                } ${
                  index == 2 && "bg-green-100"
                } min-w-[80px] w-[80px] h-[80px] min-h-[80px] md:min-w-[44px] md:w-[44px] md:h-[44px] md:min-h-[44px] font-demi text-[36px] md:text-rg text-[#fff] rounded-[50%] bg-blue-100 flex items-center justify-center`}
              >
                0{index + 1}
              </div>
              <p className="font-demi leading-[25px] text-lg md:text-rg md:font-medium text-blackPrimary-100">
                {_}
              </p>
            </div>
          ))}
        </div>
        <div className="absolute w-[500px] md:w-auto bottom-[-50px] right-0 md:hidden">
          <img src="/images/vitacorplogo.png" alt="vitalogo" />
        </div>
      </div>
    </div>
  );
});
