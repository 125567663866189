import { memo } from "react";
import { ReactSVG } from "react-svg";
import { DownButton } from "Components/Buttons/DownButton";
import useWindowSize from "Hooks/useWindowSize";

const aboutList = [
  "სამედიცინო პროვაიდერებისა და სერვისების  ყველაზე დიდი რაოდენობა და არჩევანი.",
  "მომენტალური გააქტიურება, ლოდინის პერიოდის გარეშე",
  "ულიმიტო წვდომა ყველა პროფილის კლინიკასთან",
  "VITA-ს გამოყენება არ იწვევს  საყოველთაო ან კერძო დაზღვევის გაუქმებას. პირიქით, ვიტა არის დაზღვევის იდეალური შემავსებელი.",
  "სააფთიაქო პროდუქტები",
  "გამოიყენება ულიმიტო ჯერადობით იგივე ან სხვადასხვა კლინიკებში.",
];

const AboutHeader = () => (
  <>
    <h4 className="font-mzeqala text-[20px] text-raspberry-100 lg:text-[20px]">
      ვიტას შესახებ
    </h4>
    <h2 className="relative flex items-center font-bold text-blackPrimary-100 text-[40px] mb-[20px] lg:leading-[25px] lg:mb-[10px] lg:text-sloganLgSize md:text-sloganMdSize">
      რატომ ვიტა?
      <ReactSVG
        className="absolute left-[300px] lg:hidden"
        src="/images/icons/whyvitaarrow.svg"
      />
    </h2>
    <p className="font-regular text-blackPrimary-60 text-[20px] block mb-[20px] lg:text-[14px]">
      VITA ბარათით თქვენ მიიღებთ მუდმივმოქმედ ფასდაკლებას 200-ზე მეტ, კლინიკასა
      და სააფთიაქო ქსელში ულიმიტოდ და მთელი საქართველოს მასშტაბით.
    </p>
  </>
);

export const AboutVita = memo(function AboutVitaComponrny({
  handleSectionClick,
}: any) {
  const { width } = useWindowSize();
  return (
    <>
      <div className="container hidden 2xl:block mt-[60px]">
        <AboutHeader />
      </div>
      <div className="container flex md:flex-col mt-[157px] 2xl:mt-[40px] lg:mt-[20px] mb-[84px] lg:mb-[60px] items-start gap-3">
        <div className="max-w-[620px] 2xl:min-w-[480px] lg:min-w-0 lg:w-full lg:max-w-full xl:min-w-[400px] 2xl:pr-0 w-full pr-[64px] relative">
          <div className="realtive">
            <img className="lg:w-full" src="/images/aboutwhitabanner.webp" />
            <div className="absolute w-[calc(100%-66px)] px-6 bottom-6 flex items-center gap-4">
              <img
                className="lg:w-[40px]"
                src="/images/icons/vitaminilogo.svg"
              />
              <span className="text-[28.64px] lg:text-[16px] lg:leading-6 font-bold text-[#fff] leading-[30.7px]">
                ᲯᲐᲜᲛᲠᲗᲔᲚᲝᲑᲐᲨᲘ <br /> ᲓᲐ ᲑᲔᲓᲜᲘᲔᲠᲔᲑᲐᲨᲘ
              </span>
            </div>
          </div>
          <div
            onClick={handleSectionClick}
            className="absolute top-[50%] translate-y-[-50%] right-0 2xl:hidden"
          >
            <DownButton />
          </div>
        </div>
        <div>
          <div className="2xl:hidden">
            <AboutHeader />
          </div>
          {width > 1530 ? (
            <div className="grid grid-cols-auto-fit-300 gap-row-[16px] gap-col-[10px] 2xl:grid-cols-1">
              <div>
                {aboutList?.slice(0, 3).map((item, key) => (
                  <span
                    className="flex py-[14px] lg:py-[10px] items-center leading-[22px] gap-[13px] font-medium text-md lg:text-[14px] text-blackPrimary-100"
                    key={key}
                  >
                    <ReactSVG src="/images/icons/verify.svg" /> {item}
                  </span>
                ))}
              </div>
              <div>
                {aboutList?.slice(3, 6).map((item, key) => (
                  <span
                    className="flex py-[14px] lg:py-[10px] items-center leading-[22px] gap-[13px] font-medium text-md lg:text-[14px] text-blackPrimary-100"
                    key={key}
                  >
                    <ReactSVG src="/images/icons/verify.svg" /> {item}
                  </span>
                ))}
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-auto-fit-300 gap-row-[16px] gap-col-[10px] 2xl:grid-cols-1">
              {aboutList?.map((item, key) => (
                <span
                  className="flex py-[14px] lg:py-[10px] items-center leading-[22px] gap-[13px] font-medium text-md lg:text-[14px] text-blackPrimary-100"
                  key={key}
                >
                  <ReactSVG src="/images/icons/verify.svg" /> {item}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
});
