import { Button } from "@mui/material";
import { SeeAll } from "Components/Buttons/SeeAll";
import { VitaButton } from "Components/Buttons/VitaButton";
import { memo } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

let providers = [
  {
    title: "სამედიცინო დაწესებულებები",
    percent: 50,
    link: "/clinics/samedicinodawesebulebebi",
  },
  {
    title: "მენტალური ჯანმრთელობა",
    percent: 35,
    link: "/clinics/mentalurijanmrteloba",
  },
  {
    title: "სასწრაფო დახმარება",
    percent: 30,
    link: "/clinics/saswrapodaxmareba",
  },
  {
    title: "სტომატოლოგია",
    percent: 50,
    link: "/clinics/stomatologia",
  },
  {
    title: "სილამაზე და ესთეტიკა",
    percent: 50,
    link: "/clinics/silamazedaestetika",
  },
  {
    title: "აფთიაქები",
    percent: 40,
    link: "/clinics/aptiakebi",
  },
];

const VitaProvidersHeader = () => (
  <>
    <h4 className="text-[20px] font-mzeqala text-raspberry-100 mb-0">
      პროვაიდერები
    </h4>
    <h2 className="font-bold text-[40px] lg:text-sloganLgSize md:text-sloganMdSize leading-[56px] md:leading-[24px] text-blackPrimary-100 mb-[24px] lg:mb-[14px] lg:leading-[34px]">
      ვიტას პროვაიდერი ორგანიზაციები
    </h2>
    <p className="font-regular font-[20px] lg:text-md md:text-rg text-blackPrimary-60 leading-[30px] mb-[20px]">
      VITA ბარათით თქვენ მიიღებთ მუდმივმოქმედ ფასდაკლებას 200-ზე მეტ, კლინიკასა
      და სააფთიაქო ქსელში
    </p>
  </>
);

export const VitaProviders = memo(function VitaProvidersComponent() {
  return (
    <>
      <div className="hidden container lg:block">
        <VitaProvidersHeader />
      </div>
      <div className="container flex lg:flex-col items-start gap-[71px] md:gap-[32px] mb-[120px] lg:mb-[60px]">
        <div className="w-[647px] lg:w-[447px] md:w-full lg:m-auto">
          <img src="/images/providers.webp" alt="providers" />
        </div>
        <div className="lg:w-full">
          <div className="lg:hidden">
            <VitaProvidersHeader />
          </div>
          <div>
            {providers?.map((provider, index) => {
              return (
                <Link key={index} to={provider.link}>
                  <div className="group vtbtn flex justify-between items-center border-b-[1px] last:border-none border-b-gray-100 py-[18px]">
                    <h3 className="group-hover:text-green-100 transition-all font-demi text-[20px] lg:text-md md:text-md text-blackPrimary-100 leading-[30px]">
                      {provider.title}
                    </h3>
                    <div className="flex items-center gap-[24px] md:gap-2">
                      <span className="text-raspberry-100 text-[20px] lg:text-md md:text-md font-medium leading-[30px]">
                        {provider.percent} % მდე
                      </span>
                      <Button
                        aria-label="arrow"
                        className="overflow-hidden transition-all bg-green-100 flex align-center justify-center p-[18px] md:p-0 md:w-[32px] md:h-[32px] md:max-w-[32px] md:min-w-[32px] rounded-[50%]"
                      >
                        <div className="absolute w-[96%] h-[96%] group-hover:bottom-[50%] group-hover:left-[50%] group-hover:translate-x-[-50%] group-hover:translate-y-[50%] bottom-[-100px] left-[-100px] transition-all bg-[#fff] rounded-[50%]"></div>
                        <ReactSVG
                          className="svgfilter"
                          src="/images/icons/rotatedarrow.svg"
                        />
                      </Button>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
          <div className="mt-[18px] flex items-center justify-between lg:flex-col-reverse lg:gap-6">
            <VitaButton variant="secondary" />
            <Link to={"/clinics"}>
              <SeeAll />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
});
