import { memo } from "react";

const usageList = [
  "გაეცანი VITA-ს ვებ გვერდზე გამოქვეყნებულ კლინიკებსა და სერვისებს",
  "გამოიწერე VITA ბარათი სულ რამდენიმე კლიკით",
  "ესტუმრე სასურველ სამედიცინო დაწესებულებას",
  "წარადგინე პირადობის დამადასტურებელი მოწმობა",
];

export const VitaUsage = memo(function VitaUsageComponent({ usageRef }: any) {
  return (
    <div ref={usageRef} className="container mb-[138px] lg:mb-[60px]">
      <div className="mb-[40px] lg:mb-[32px]">
        <h4 className="font-mzeqala text-raspberry-100 text-[20px]">
          გამოყენების წესები
        </h4>
        <h2 className="font-bold text-blackPrimary-100 text-[40px] leading-[56.84px] lg:leading-[34px] md:leading-[24px] mb-[24px] lg:text-sloganLgSize md:text-sloganMdSize lg:mb-[10px]">
          როგორ გამოვიყენო ჯანდაცვის ბარათი?
        </h2>
        <p className="text-[20px] lg:text-md md:text-rg lg:leading-[24px] font-medium text-blackPrimary-60 leading-[30px]">
          რატომ უნდა აგვირჩიოთ ჩვენ, გაიგეთ მეტი ჩვენ შესახებ
        </p>
      </div>
      <div className="flex gap-[30px] lg:grid lg:grid-cols-2 md:grid-cols-1">
        {usageList?.map((item, index) => (
          <div
            className="w-full overflow-hidden flex flex-col lg:flex-row lg:items-center lg:gap-[12px] gap-[22px]"
            key={index}
          >
            <div className="flex gap-4 flex-row-reverse lg:flex-row">
              <img
                className="max-w-[calc(100%-63px)] contain lg:hidden"
                src="/images/arrow.png"
                alt="arrow"
              />
              <span className="flex items-center justify-center w-[44px] min-w-[44px] h-[44px] rounded-[50%] bg-blue-100 text-[#fff] font-demi text-[20px]">
                0{index + 1}
              </span>
            </div>
            <span className="text-left max-w-[80%] lg:max-w-full m-auto lg:m-0 font-medium text-md lg:text-rg leading-[22/74px] text-blackPrimary-100">
              {item}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
});
