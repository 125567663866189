import { memo } from "react";

let benefits = [
  {
    title: "მეტი მომხმარებელი",
    // description: `წვდომა სამომხმარებლო აუდიტორიაზე და ლოიალური მომხმარებლის
    // შეძენის შესაძლებლობა`,
  },
  {
    title: "სასურველი მიმართულებების დატვირთვა",
  },
  {
    title: "ფასდაკლების მოქნილი მექანიზმები",
    // description: `მომხმარებელი , რომელმაც მიიღო პოზიტიური გამოცდილება, ამ
    // სერვისს/პროდუქტს რეკომენდაციას უწევს მინიმუმ 3 თავის ნაცნობთან`,
  },
  {
    title: "ციფრული სერვისები",
    // description: `ვიტას ციფრული არხებით მომხმარებელთათვის პროვაიდერი
    // ორგანიზაციების სერვისებისა და პროდუქტების აქტიური შეთავაზება.
    // ზუსტი ინფორმაციის მიმოცვლის მიზნით პროვაიდერ ორგანიზაციებთან
    // ციფრული ინტეგრაციის შესაძლებლობა. მომხმარებლებთან მუდმივი
    // კომუნიკაცია ციფრულ არხებში პროვაიდერ ორგანიზაციებთან და მათ
    // სერვისებთან დაკავშირებით.`,
  },
];

export const MedBenefit = memo(function MedBenefitComponent() {
  return (
    <div className="customxlsize:container customxlsize:px-[48px] lg:px-[32px] sm:px-[16px] customxlsize:pt-[30px] flex gap-[2.604vw] relative max-w-[1920px] m-auto items-center mb-[120px] customxlsize:mb-[60px] md:mb-[40px]">
      <div className="w-[35.677vw] min-w-[685px] customxlsize:hidden h-full">
        <img
          className="object-cover w-full h-full"
          src="/images/medsectorbanner.png"
          alt="medsectorbanner"
        />
      </div>
      <div>
        <h3 className="text-sloganSize lg:text-sloganLgSize md:text-sloganMdSize font-bold text-blackPrimary-100 leading-[56px] lg:leading-[34px] md:leading-[24px]  mb-[40px] md:mb-[32px]">
          ძირითადი სარგებელი
        </h3>
        <div className="mr-[9.26vw] 2xl:mr-[48px] customxlsize:mr-0">
          {benefits?.map(({ title }, index) => (
            <div
              key={index}
              className="flex gap-[32px] items-center mb-[40px] md:mb-[16px]"
            >
              <div className={`${index == 0 && 'bg-blue-100'} ${index == 1 && 'bg-yellow'} ${index == 2 && 'bg-green-100'} ${index == 3 && 'bg-raspberry-100'} md:hidden w-[60px] min-w-[60px] rounded-[50%] h-[60px] flex items-center justify-center text-[#fff] text-[24px] font-demi`}>
                0{index + 1}
              </div>
              <div>
                <h3 className="text-[24px] md:text-md font-bold text-blackPrimary-100 mb-[10px] leading-[34px] md:flex md:items-center md:gap-3">
                  <div className={`${index == 0 && 'bg-blue-100'} ${index == 1 && 'bg-yellow'} ${index == 2 && 'bg-green-100'} ${index == 3 && 'bg-raspberry-100'} hidden md:flex w-[44px] min-w-[44px] rounded-[50%] h-[44px] items-center justify-center text-[#fff] text-[14px] font-demi`}>
                    0{index + 1}
                  </div>
                  {title}
                </h3>
                {/* <span className="text-md md:text-rg font-demi text-blackPrimary-60">
                  {description}
                </span> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});
