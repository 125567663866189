import { VitaPercent } from "Components/Buttons/VitaPercent";

export const OfferType1 = ({
  imgUrl,
  discount,
  title,
  sortNum,
  description,
}: {
  imgUrl: string;
  discount: string;
  title: string;
  sortNum?: number;
  description?: string;
}) => (
  <div className="bg-[#fff] h-full md:min-w-[211px] pt-[20px] pb-[24px] rounded-[12px] flex flex-col items-center justify-center gap-2">
    <div className="flex justify-center items-center overflow-hidden w-[88px] min-h-[88px] h-[88px]">
      <img
        className="w-full h-full object-contain"
        src={imgUrl}
        alt="offer image"
      />
    </div>
    <h4 className="text-rg mt-2 font-bold text-center px-4 mb-0 leading-[21px]">
      {title}
    </h4>
    <p className="mt-0 mb-2 px-4 text-center text-blackPrimary-60 font-bold text-rg">{description}</p>
    <div className="mt-auto">
      <VitaPercent percent={discount} />
    </div>
  </div>
);
