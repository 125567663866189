import { BasicBreadcrumbs } from "Components/Breadcrumbs/Breadcrumbs";
import JsonLd from "Components/JSONLD/JsonLd";
import useWindowSize from "Hooks/useWindowSize";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { ReactSVG } from "react-svg";

const faqDto = [
  {
    title: "როგორ შევიძინო VITA ჯანდაცვის ბარათი?",
    description: `VITA ჯანდაცვის ბარათი ყოველთვიური გამოწერის შეძენა მომხმარებელს შეუძლია საკუთარი თავისთვის და ოჯახის წევრისთვის (მათ შორის არასრულწლოვანი ოჯახის წევრისთვის), ვებ გვერდზე Vitaapp.ge
        VITA ჯანდაცვის ბარათის ყიდვამდე აუცილებელია, რომ მოხმარებელმა გაიაროს რეგისტრაცია და ავტორიზაცია ვებ გვერდზე Vitaapp.ge
        რეგისტრირებული ფიზიკური პირი Vitaapp.ge-ზე შეიძენს Vita ბარათის ყოველთვიურ გამოწერას, თვეში მხოლოდ 15 ლარად.
        რადგან Vita ბარათი ვირტუალურია და აქტიურდება მომხმარებლის პირად ნომერზე, ყიდვისას აუცილებელია პირადობის მოწმობაში მითითებული ნომრის შეყვანა შესაბამის ველში.
        მომხმარებელს ასევე შეუძლია შეიძინოს Vita ბარათი მხოლოდ 1 თვით.
        შეძენილი Vita ბარათი აქტიურდება შეძენისთანავე.
        Vita -ს გამოწერის ღირებულების თანხის ჩამოჭრა მოხდება ყოველ 30 კალენდარულ დღეში.
        Vita ბარათის შეძენაში მომხმარებელს შეუძლია ისარგებლოს ვიტას მომხმარებელთა მხარდაჭერის გუნდის დახმარებით, ნომერზე: 032 2 185577`,
  },
  {
    title: "როგორ დავრეგისტრირდე და გავხსნა ჩემი ანგარიში",
    description: `მომხმარებელი Vitaapp.ge საიტზე რეგისტრირებისას ავსებს ველებს: სახელი, გვარი, 
    ტელეფონის ნომერი და თანხმდება სამომხმარებლო ხელშეკრულებას და 
    კონფიდენციალურობა-მონაცემების დამუშავების წესებს.
    რეგისტრაციისას მოწმდება, რომ იუზერს ნამდვილად აქვს წვდომა იმ ნომერზე, 
    რომლითაც რეგისტრირდება. ამისთვის მას ერთჯერადად ეგზავნება ვალიდაციის კოდი,
    რომელიც მომხმარებელს შეყავს შესაბამის ველში.
    ვიტაზე რეგისტრაციისას მომხმარებელს შეუძლია ისარგებლოს ვიტას მომხმარებელთა 
    მხარდაჭერის გუნდის დახმარებით ნომერზე: 032 2 185577`,
  },
  {
    title: "შეუძლია თუ არა Vita ბარათის შეძენა უცხო ქვეყნის მოქალაქეს",
    description: `ვიტა-ს შეძენა ასევე შეუძლია საქართველოს ტერიტორიაზე მყოფ უცხო ქვეყნის 
    მოქალაქეს, რომელიც ფლობს ქართულ ტელეფონის ნომერს.
    უცხო ქვეყნის მოქალაქის მიერ Vita ბარათით სარგებლობისას გასათვალისწინებელია, 
    რომ საქართველოს არარეზიდენტი პირებისთვის საქართველოში სამედიცინო 
    სერვისების ფასები 50% ან 100%-ით მეტია (კონკრეტული კლინიკის პოლიტიკის 
    მიხედვით), ვიდრე საქართველოს მოქალაქეებისთვის განსაზღვრული ფასი. უცხო 
    ქვეყნის მოქალაქეს შეუძლია ისარგებლოს Vita-ს მიერ შეთავაზებული ფასდაკლების %-
    ით კლინიკაში დადგენილი უცხო ქვეყნის მოქალაქისთვის განკუთვნილი ზედა ფასიდან.`,
  },
  {
    title:
      "თუ მომხმარებელს საკმარისი თანხა არ აქვს ანგარიშზე გადახდის დღისთვის. ბარათის გაუქმება",
    description: `თუ მომხმარებელს ჩართული აქვს Vita-ს ყოველთვიური გამოწერა და ანგარიშზე 
    საკმარისი თანხა არ აღმოაჩნდა გადახდის თარიღისთვის, სისტემას მის ბარათს კიდევ 
    მე-7 დღეს და მე-14 დღეს მიაკითხავს თანხის ჩამოჭრის მიზნით.
    თუ თანხის ჩამოჭრა მე-3 ცდაზე, მე-14 დღესაც ვერ მოხერხდება - მომხმარებელს 
    გაუუქმდება ვიტა ბარათი.`,
  },
  {
    title: "Vita-ს შეძენა ოჯახის წევრისთვის",
    description: `ფიზიკურ პირს ვიტას შეძენა შეუძლია თავისი სრულწლოვანი და არასრულწლოვანი 
    ოჯახის წევრისთვისაც.

    ოჯახის წევრის ბარათის შეძენისას მომხმარებელს სისტემა ერთჯერადად ჩამოაჭრის 
    ოჯახის წევრის ბარათის ღირებულების იმ თანხას, რომელიც დაითვლება ამ ოჯახის 
    წევრის ბარათის შეძენიდან, მომხმარებლის საკუთარი ბარათის გადახდის დღემდე.

    მხოლოდ მომხმარებლის ბარათის გადახდის დღიდან ორივე ბარათის, მომხმარებლის და 
    მისი ოჯახის წევრის ბარათების ღირებულების ჩამოჭრა მოხდება ერთდროულად.

    მაგ: თუ გადახდის დღე თავის ბარათზე მომხმარებელს აქვს 25 რიცხვი და ოჯახის 
    წევრის ბარათი  იყიდა 10 რიცხვში, ყიდვის მომენტში ჩამოეჭრება ბარათის ფლობის 
    25-10=15 დღის საფასური, ხოლო შემდეგ 25 რიცხვიდან ერთდროულად ჩამოეჭრება 2
    ბარათის ფლობის საფასური.

    ოჯახის წევრის ვიტა ბარათი აქტიურდება შეძენისთანავე.`,
  },
  {
    title: "როგორ გამოვიყენო Vita ჯანდაცვის ბარათი კლინიკაში",
    description: `კლინიკაში ვიზიტისას Vita-ს ფასდაკლებების მისაღებად, მომხმარებელმა უნდა 
    გაიაროს იდენტიფიკაცია პირადობის ნომრით, რისთვისად რეგისტრატურაში უნდა 
    წარადგინოს თავისი ვალიდური პირადობის მოწმობა და რეგისტრატოს აუხსნას, რომ 
    სურს გადახდა Vita ბარათის გამოყენებით.`,
  },
  {
    title: "როგორ გამოვიყენო Vita ჯანდაცვის ბარათი აფთიაქებში",
    description: `აფთიაქში ვიზიტისას Vita-ს ფასდაკლებების მისაღებად, მომხმარებელმა უნდა გაიაროს
    იდენტიფიკაცია პირადობის ნომრით, რისთვისად ფარმაცევტს უნდა წარუდგინოს 
    თავისი ვალიდური პირადობის მოწმობა და აუხსნას, რომ სურს გადახდა Vita ბარათის 
    გამოყენებით.
    პსპ აფთიაქებში Vita-ს  ფასდაკლების მიღებისთვის მომხმარებელს ასევე უნდა ჰქონდეს
    პსპ-ს ოჯახის ბარათი. თუ მომხმარებელს პსპ-ს ოჯახის ბარათი არ აქვს, ფარმაცევტი 
    ადგილზევე გაუხსნის მას.`,
  },
  {
    title: "როგორ შევცვალო პირადი მონაცემები?",
    description: `პირადი ნომრის ცვლილების გარდა, ნებისმიერი სხვა პირადი მონაცემის ცვლილება 
    შესაძლებელია Vitaapp.ge-ზე პირადი კაბინეტიდან. პირადის ნომრის ცვლილების 
    შემთხვევაში, აუცილებელია დაუკავშირდეთ მომხმარებელთა მხარდაჭერის გუნდს 
    ნომერზე 032 2185577.`,
  },
  {
    title: "შეძენისას რომელი გადახდის საშუალებებით შემიძლია სარგებლობა?",
    description: `Vitaapp.ge-ზე Vita ჯანდაცვის ბარათის ყოველთვიური გამოწერის ყიდვა შესაძლებელია
    ნებისმიერი ბანკის ვალიდური საკრედიტო ან სადებეტო ბარათით.`,
  },
  {
    title: "შესაძლებელია თუ არა Vita ჯანდაცვის ბარათის გამოწერი გაუქმება?",
    description: `Vita ჯანდაცვის ბარათის გამოწერის გაუქმება მომხმარებეს შეუძლია პირადი 
    კაბინეტიდან სულ რამდენიმე კლიკით.
    გამოწერის გაუქმებისთვის მომხმარებელს ასევე შეუძლია ისარგებლოს ვიტას 
    მომხმარებელთა მხარდაჭერის გუნდის დახმარებით ნომერზე: 032 2 185577`,
  },
  {
    title: "სად ვნახო პარტნიორი ობიექტების ფასდაკლებები?",
    description: `Vitaapp.ge გვერდზე „ყველა პროვაიდერი“-ს განყოფილებაში შესაძლებელია ყველა 
    პროვაიდერი კლინიკისა და სააფთიაქო ქსელის მომსახურებებისა და პროდუქტების 
    ნახვა მიმართულებების მიხედვით.
    თითოეული კლინიკის გვერზე მომხმარებელს შეუძლია დაათვალიეროს ამ კლინიკის 
    მომსახურებები კლინიკაში დადგენილი საწყისი ფასებით და Vita-ს ფასდაკალებით.
    კლინიკის გვერდზე ასევე შესაძლებელია ინფორმაციის მიღება ამ კლინიკის 
    ფილიალების და მათი საკონტაქტო მონაცემების შესახებ.`,
  },
  {
    title: "როგორ შევიძინო კორპორატიული Vita?",
    description: `კორპორატიული Vita ბარათის შესაძენად Vitaapp.ge-ზე გადადით გვერდზე 
    „კორპორატიული“ და შეავსეთ გვერდის ბოლოში მოცემული შესავსები ფორმა. ანდა 
    მოგვმართეთ ელ-ფოსტაზე: Info@vitaapp.ge`,
  },
  {
    title: "როგორ გავხდე Vita-ს პროვაიდერი სამედიცინო დაწესებულება?",
    description: `იმისათვის, რომ დარეგისტრირდეთ Vita ბარათის პროვაიდერ ორგანიზაციად, 
    Vitaapp.ge-ზე გადადით გვერდზე „სამედიცინო სექტორისთვის“ და შეავსეთ გვერდის 
    ბოლოში მოცემული შესავსები ფორმა. ანდა მოგვმართეთ ელ-ფოსტაზე: Info@vitaapp.ge`,
  },
];

export const FAQ = () => {
  const { width } = useWindowSize();
  return (
    <div className="container sm:w-full sm:max-w-full sm:px-0 sm:pt-2 sm:pb-10 pt-[44px] pb-[120px]">
      {width <= 640 && (
        <div className="px-4">
          <BasicBreadcrumbs
            breadcrumbs={[
              {
                title: "ხშირად დასმული კითხვები",
                link: "/",
              },
            ]}
          />
        </div>
      )}
      <h1 className="sm:px-[16px] sm:text-[20px] sm:mt-[16px] font-bold mb-4 text-sloganSize text-blackPrimary-100">
        ხშირად დასმული კითხვები
      </h1>
      <div className="grid grid-cols-2 xl:grid-cols-1 gap-0 gap-x-7">
        <div>
          {faqDto?.map(
            (item, index) =>
              index % 2 == 0 && <FaqItem key={index} faq={item} />
          )}
        </div>
        <div>
          {faqDto?.map(
            (item, index) =>
              index % 2 !== 0 && <FaqItem key={index} faq={item} />
          )}
        </div>
      </div>
    </div>
  );
};

export const FaqItem = ({ faq }: { faq: any }) => {
  const [state, setState] = useState(false);

  let ldData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqDto?.map((e: any, index: any) => ({
      "@type": "Question",
      name: e.title,
      acceptedAnswer: {
        "@type": "Answer",
        text: e.description,
      },
    })),
  };

  return (
    <div>
      <Helmet>
        <title>ხშირად დასმული შეკითხვები | VITA</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="description"
          content={`გაინტერესებთ ინფორმაცია VITA-ს შესახებ? ნახე ხშირად დასმული კითხვები და პასუხები`}
        />
        <script type="application/ld+json">{JSON.stringify(ldData)}</script>
      </Helmet>
      <div
        onClick={() => setState((prev) => !prev)}
        className="h-[88px] xl:h-auto  border-solid border-b-[1px] border-b-blackPrimary-10 cursor-pointer"
      >
        <div className="flex gap-3 justify-between items-center py-[32px] sm:px-[16px] sm:py-[24px] pr-[24px]">
          <h2
            className={`${
              state && "text-green-100"
            } font-bold text-md sm:text-rg`}
          >
            {faq?.title}
          </h2>
          <ReactSVG
            className={`${state && "activeArrow"} transition-[0.3s]`}
            src="/images/icons/arrow.svg"
          />
        </div>
      </div>
      {state && (
        <div className="sm:px-[16px] my-7">
          <span
            className="font-regular text-rg text-blackPrimary-100"
            dangerouslySetInnerHTML={{ __html: faq.description }}
          />
        </div>
      )}
    </div>
  );
};
