import { CorporateButton } from "Components/Buttons/CorporateButton";
import { DownButton } from "Components/Buttons/DownButton";
import { memo } from "react";
import { ReactSVG } from "react-svg";

export const MedSectorFeature = memo(function MedSectorFeatureComponent({
  contactUsRef,
  handleForMedSection
}: any) {
  return (
    <div className="bg-medsectorbg sm:bg-medsectormobilebg sm:h-[calc(100vh-82px)] relative bg-no-repeat bg-[left_50%_top_0%] bg-cover h-[729px] w-full flex items-center">
      <div className="container flex flex-col items-start">
        <div className="flex relative flex-col items-center mb-[68px] md:mb-[40px] gap-2 before:content-[''] before:bg-medsectorline before:absolute before:top-[-150px] before:left-[-100px] before:w-[235px] md:before:w-[135px] before:bg-no-repeat before:bg-contain before:h-[290px] md:before:top-[-85px]  sm:before:left-0 sm:before:top-[-100px] md:text-center md:justify-center">
          <h1 className="lg:flex sm:justify-center lg:flex-wrap relative font-bold text-[#ffffff] text-sloganSize lg:text-sloganLgSize md:text-sloganMdSize leading-[58px] md:leading-[30px]">
            VITA
            <span className="text-yellow relative ml-[6px] mr-[6px]">
              პროვაიდერი
            </span>
            კლინიკებისთვის.
          </h1>
        </div>
        <p className="font-medium text-lg md:text-rg text-[#fff] leading-[25.5px] mb-12 sm:text-center">
        ვიტა მუდმივად ასტიმულირებს თავის მომხმარებელს, რათა მათ აქტიურად გამოიყენონ პრევენციული მედიცინა და ჯანმრთელობის შენარჩუნებისთვის აუცილებელი მომსახურებები და პროდუქტები და განახორციელონ ვიზიტები სამედიცინო, სტომატოლოგიურ და ესთეტიკურ კლინიკებსა და აფთიაქებში. 
        </p>
        <CorporateButton
          onClick={() =>
            contactUsRef?.current?.scrollIntoView({
              behavior: "smooth",
              block: "end",
            })
          }
          label="შემოგვიერთდი"
          className="bg-green-100 sm:w-full"
          icon={
            <ReactSVG
              className="svgfiltervol2"
              src="/images/icons/explore.svg"
            />
          }
        />
        <div onClick={handleForMedSection} className="absolute bottom-[-65px] right-[250px] lg:hidden">
          <DownButton />
        </div>
      </div>
    </div>
  );
});
