import { Skeleton } from "@mui/material";
import { BasicBreadcrumbs } from "Components/Breadcrumbs/Breadcrumbs";
import { ContactButton } from "Components/Buttons/ContactButton";
import { Location } from "Components/Location/Location";
import { ContactModal } from "Components/Modals/Clinics/ContactModal";
import {
  WorkHours,
  days,
} from "Components/PageSections/Clinics/WorkHours/WorkHours";
import { Phone } from "Components/Phone/PhoneComponent";
import { SocialComponent } from "Components/Social/Social";
import { ClinicDetailTabs } from "Components/Tabs/ClinicDetailTabs";
import { useClinicdetailsQuery } from "Hooks/Queries/useClinidetailsQuery";
import { useSlugs } from "Hooks/useQuery";
import useWindowSize from "Hooks/useWindowSize";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { ReactSVG } from "react-svg";

export const ClinicDetails = () => {
  const { width } = useWindowSize();
  const [isOpenContact, setIsOpenContact] = useState(false);
  const [isOpenClinicInfo, setIsOpenClinicInfo] = useState(false);
  const slugs = useSlugs();
  const slug = slugs[slugs.length - 1];

  const {
    clinicDetails,
    isErrorClinicDetails,
    isLoadingClinicDetails,
    isSuccessClinicDetails,
  } = useClinicdetailsQuery(slug);
  const mails = clinicDetails?.clinicContactInfos?.filter(
    (e: any) => e.type == "MAIL"
  );
  const insta = clinicDetails?.clinicContactInfos?.filter(
    (e: any) => e.type == "INST"
  )[0];
  const fb = clinicDetails?.clinicContactInfos?.filter(
    (e: any) => e.type == "FB"
  )[0];
  const phones = clinicDetails?.clinicContactInfos?.filter(
    (e: any) => e.type == "PHONE"
  );

  const handleModal = useCallback(
    (state: boolean) => setIsOpenContact(state),
    []
  );

  const [jsonld, setJsonld] = useState({});

  useEffect(() => {
    if (clinicDetails) {
      setJsonld({
        "@context": "https://schema.org",
        "@type": "MedicalOrganization",
        name: clinicDetails?.name,
        address: {
          "@type": "PostalAddress",
          streetAddress: clinicDetails?.address?.address,
          addressLocality: clinicDetails?.address?.region,
          addressCountry: "Georgia",
        },
        telephone: phones && phones[0]?.value,
        email: mails && mails[0]?.value,
        description: clinicDetails?.clinicDescriptionDTO?.description,
        openingHours: days
          ?.map(
            (day: any, i: number) =>
              clinicDetails?.workingHours?.filter(
                (e: any) => e?.dayId === day.dayId
              )?.[0]?.startHour !== undefined &&
              `${day.title} - ${
                clinicDetails?.workingHours?.filter(
                  (e: any) => e?.dayId === day.dayId
                )?.[0]?.startHour
              }/${
                clinicDetails?.workingHours?.filter(
                  (e: any) => e?.dayId === day.dayId
                )?.[0]?.endHour
              }`
          )
          .filter((e) => e !== false),
      });
    }
  }, [clinicDetails]);

  return (
    <div className="flex container mt-[30px] sm:mt-[15px] lg:flex-col lg:w-full lg:max-w-full lg:px-0 gap-[30px] sm:gap-0">
      <Helmet>
        <title>
          {`${clinicDetails?.name?.toString()}`} | ისარგებლე ფასდაკლებებით |
          VITA-ს დახმარებით
        </title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="description"
          content={`კლინიკა ${clinicDetails?.name} საშუალებას გაძლევს ისარგებლო 50% მდე ფასდაკლებებით კლინიკის სხვადასხვა სამედიცინო მომსახურებებზე`}
        />
        <script type="application/ld+json">{JSON.stringify(jsonld)}</script>
      </Helmet>
      {width <= 1080 && (
        <ContactModal
          clinic={clinicDetails}
          open={isOpenContact}
          close={() => handleModal(false)}
        />
      )}
      {width <= 640 && (
        <div className="px-[16px]">
          <BasicBreadcrumbs
            breadcrumbs={[
              {
                title: "კლინიკები",
                link: "/clinics",
              },
              {
                title: `${clinicDetails?.name}`,
                link: "/",
              },
            ]}
          />
        </div>
      )}
      <div className="min-w-[290px] sm:p-[16px] sm:min-w-full sm:w-full lg:w-full w-[290px] flex flex-col gap-5 mb-[30px] lg:mb-0">
        <div className="bg-gray-30 rounded-[12px] flex flex-col items-center justify-center px-[55px] py-[42px] sm:py-[24px]">
          <img
            src={clinicDetails?.mainImgUrl}
            width={180}
            height={180}
            alt="clinic image"
          />
          <h1 className="text-rg font-bold text-blackPrimary-100">
            {clinicDetails?.name}
          </h1>
        </div>
        <div className="lg:hidden bg-gray-30 flex flex-col gap-10 rounded-[12px] py-[32px] px-[24px]">
          <div>
            <Location
              // clinic={clinicDetails}
              address={clinicDetails?.address}
              isLoadingClinic={isLoadingClinicDetails}
              isErrorClinic={isErrorClinicDetails}
              // color="rgba(56, 56, 56, 0.60)"
            />
          </div>
          {(phones?.length !== 0 || mails?.length !== 0) && (
            <div className="flex flex-col gap-5">
              {phones?.map((phone: any) => (
                <Phone phone={phone?.value} color="rgba(56, 56, 56, 0.60)" />
              ))}

              {mails?.map((mail: any) => (
                <div className="flex items-center font-regular gap-2 text-blackPrimary-60">
                  <ReactSVG src="/images/icons/email.svg" /> {mail?.value}
                </div>
              ))}
            </div>
          )}
          {fb !== undefined ||
            (insta !== undefined && (
              <div className="flex flex-col gap-5">
                {fb?.value !== undefined && (
                  <SocialComponent
                    img={"/images/icons/social/facebook.svg"}
                    url={fb?.value}
                    color="rgba(56, 56, 56, 0.60)"
                  />
                )}
                {insta?.value !== undefined && (
                  <SocialComponent
                    img={"/images/icons/social/instagram.svg"}
                    url={insta?.value}
                    color="rgba(56, 56, 56, 0.60)"
                  />
                )}
              </div>
            ))}
          <WorkHours workingHours={clinicDetails?.workingHours} />
        </div>
      </div>
      <div className="w-full lg:px-[32px] md:px-[16px] sm:px-0">
        {width > 640 && (
          <div className="pt-[16px]">
            <BasicBreadcrumbs
              breadcrumbs={[
                {
                  title: "კლინიკები",
                  link: "/clinics",
                },
                {
                  title: `${clinicDetails?.name}`,
                  link: "/",
                },
              ]}
            />
          </div>
        )}
        <ClinicDetailTabs
          data={{
            clinicDetails,
            isErrorClinicDetails,
            isLoadingClinicDetails,
            isSuccessClinicDetails,
          }}
        />
      </div>
      {width < 1080 && isOpenClinicInfo && (
        <div className="fixed bottom-[80px] bg-[#fff] left-[50%] translate-x-[-50%] w-full p-5 text-rg sm:text-sm font-demi text-raspberry-100 max-w-[calc(100%-64px)] sm:max-w-[calc(100%-32px)] rounded-[12px] leading-[18px]">
          ფასები მოწოდებულია კლინიკის მიერ და შესაძლოა არ ასახავდეს ბოლო
          განახლებას. ჩაწერისას გადაამოწმეთ კლინიკაშიც. კლინიკის ფასებზე
          ყოველთვის იმოქმედებს ფასდაკლების ის % , რაც VITA ბარათით არის
          განსაზღვრული.
        </div>
      )}
      {width <= 1080 && (
        <div className="fixed z-[49] bottom-3 left-[50%] translate-x-[-50%] w-full flex gap-[12px] px-8 md:px-[32px] sm:px-[16px]">
          <button
            onClick={() => setIsOpenClinicInfo(!isOpenClinicInfo)}
            className="bg-raspberry-100 rounded-[12px] flex justify-center items-center w-[52px] min-w-[52px]"
          >
            {!isOpenClinicInfo ? (
              <ReactSVG src="/images/icons/burgerError.svg" />
            ) : (
              <ReactSVG src="/images/icons/closeBurger.svg" />
            )}
          </button>
          <ContactButton onClick={() => handleModal(true)} />
        </div>
      )}
    </div>
  );
};
