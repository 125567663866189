import { DeleteModal } from "Components/Modals/Delete";
import { DeleteCard } from "Components/Modals/deleteCard";
import { memo, useEffect, useRef, useState } from "react";
import { ReactSVG } from "react-svg";

export const BankCard = memo(function BankCardComponent({ card }: any) {
  let pref = useRef<any>(null);
  let sref = useRef<any>(null);
  const date = new Date(card.saveDate);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  // Format the date to MM/DD
  const formattedDate = `${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}/${String(date.getDate()).padStart(2, "0")}`;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        pref.current &&
        !pref.current.contains(event.target as Node) &&
        sref.current &&
        !sref.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function convertToFraction() {
    const stringNum = card?.expiryDate.toString();
    const numerator = stringNum.slice(0, 2); // Get the first two digits
    const denominator = stringNum.slice(2); // Get the last two digits
    return `${numerator}/${denominator}`;
}


  return (
    <>
      <DeleteCard
        open={isOpenModal}
        close={() => setIsOpenModal(false)}
        text={`ნამდვილად გსურთ (${card.pan}) ბარათის წაშლა?`}
        cardId={card?.id}
      />
      <div className="relative z-10 py-[35px] px-[32px] bg-[#fff] rounded-[12px] flex items-center">
        <div className="bg-[#FFFFFF] px-[20px] py-[14px] border-gray-100 border-[1px] border-solid rounded-[5px]">
          <img
            className="max-w-[24px]"
            src={
              card.pan.substring(0, 1) == "5"
                ? "/images/icons/mastercard.svg"
                : "/images/icons/visacard.svg"
            }
          />
        </div>
        <div className="ml-2 flex items-center gap-2">
          <span className="font-demi text-rg text-blackPrimary-100">
            {card.pan}
          </span>
          <span>
            <ReactSVG src="/images/icons/linegap.svg" />
          </span>
          <span className="font-demi text-rg text-blackPrimary-60">
            {/* {formattedDate} */}
            {convertToFraction()}
          </span>
        </div>
        <div
          ref={sref}
          onClick={() => setIsOpen(!isOpen)}
          className="ml-auto cursor-pointer"
        >
          <ReactSVG src="/images/icons/more.svg" />
        </div>
        {isOpen && (
          <div
            ref={pref}
            className="absolute rounded-[12px] bg-[#fff] right-[0] mr-[30px] px-[16px] py-[20px] cardsToolShadow bottom-[-20px]"
          >
            <span
              onClick={() => {
                setIsOpenModal(true);
                setIsOpen(false);
              }}
              className="cursor-pointer font-demi text-rg text-blackPrimary-60"
            >
              წაშლა
            </span>
          </div>
        )}
      </div>
    </>
  );
});
