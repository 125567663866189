import { daysList } from "JSON/PSP";
import { Medication, SeasonalmedicationsType } from "Pages/Pharmacy/Psp";
import { FreeMode, Navigation } from "swiper/modules";

import { SwiperSlide, Swiper } from "swiper/react";
interface IMedicalProps {
  medicinedata: Medication[];
  prevEl: HTMLElement | null;
  nextEl: HTMLElement | null;
}
const MedicalCard = ({ medicinedata, prevEl, nextEl }: IMedicalProps) => {
  console.log("medicinedata", medicinedata.length);
  return (
    <>
      <Swiper
        breakpoints={{
          200: {
            slidesPerView: 1.6,
          },
          600: {
            slidesPerView: 1.7,
          },
          832: {
            slidesPerView: 2,
          },
          1080: {
            slidesPerView: 3, // Set the maximum slides to 4 for larger screens
          },
          1280: {
            slidesPerView: 4,
          },
          1527: {
            slidesPerView: 4,
          },
        }}
        // className={`${
        //   width > 1527 ? "" : "pr-5 pl-5 sm:pr-4 sm:pl-4"
        // } md:px-[32px] w-full`}
        className="w-full"
        spaceBetween={12}
        freeMode={false}
        navigation={{ prevEl, nextEl }}
        modules={[FreeMode, Navigation]}
        slidesPerView={1}
      >
        {medicinedata?.map((item, i) => {
          return (
            <SwiperSlide
              key={i}
              className="relative w-full rounded-xl bg-[#fff] p-6 md:p-5 gap-2"
            >
              <div
                key={item.medicine}
                className="relative h-full flex flex-col justify-between items-center" // Flex layout, center content
              >
                <div className="absolute left-0 px-3 flex items-center max-w-[107px] h-10 w-full bg-[#EF357D] rounded-xl justify-between">
                  <img
                    src="/images/vitacorplogo.png"
                    alt="vitalogo"
                    width={24}
                    height={24}
                  />
                  <span className="text-[#fff] font-bold text-[18px]">
                    - {item.sale} %
                  </span>
                </div>
                <div className="flex-grow flex items-center justify-center">
                  <img
                    src={item?.img}
                    alt="medicine img"
                    className="w-full object-contain"
                    // style={{ maxHeight: "200px" }} // Optional: Set max height for control
                  />
                </div>
                <h4 className="font-bold text-[14px] leading-5 h-10">
                  {item.medicine}
                </h4>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default MedicalCard;
