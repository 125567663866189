import { Stars } from "Components/Stars/Stars";
import { memo } from "react";
import { ReactSVG } from "react-svg";

export const SatisfiedCostumer = memo(function SatisfiedCostumerComponent({
  user,
}: any) {
  return user && (
    <div className={`flex flex-col gap-3 p-[24px] bg-gray-30 rounded-[12px] h-full`}>
      <div className="flex gap-4 items-center">
        <div style={{background: user.color}} className={`w-[72px] h-[72px] rounded-[50%] flex items-end justify-center`}>
          <ReactSVG src={user.img} />
        </div>
        <div className="flex flex-col gap-[10.5px]">
          <h5 className="text-blackPrimary-100 font-demi text-[18px]">
            {user?.name}
          </h5>
          <span className="flex items-center gap-2 font-regular text-secondaryGray-100 text-md">
            <ReactSVG src="/images/icons/pin.svg" />
            {user?.city}
          </span>
        </div>
      </div>
      <span className="text-rg text-blackPrimary-100 leading-[21px] font-medium mb-[14px] block">
        {user?.text}
      </span>
      <div className="mt-auto">
        <Stars rating={5} />
      </div>
    </div>
  );
});
