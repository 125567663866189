import { Modal } from "@mui/material";
import { Save } from "Components/Buttons/Save";
import { BankCards } from "Components/PageSections/Checkout/BankCards";
import { handleActiveSub } from "Functions/Checkout/handleActiveSub";
import { handleAddBankCardRequest } from "Functions/Checkout/handleActiveSubByNewBankcCard";
import { handleAddCardRequest } from "Functions/Checkout/handleAddCardRequest";
import { memo, useCallback, useRef, useState } from "react";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";

export const PaymentsModal = memo(function PaymentsModalComponent({
  open,
  close,
  setPaymentMethod,
  paymentMethod,
  fromProfile = false,
  type = "default", // default means add card, activeSub means active subscribe
  refetch,
}: {
  open: boolean;
  close: () => void;
  setPaymentMethod?: any;
  paymentMethod?: any;
  fromProfile?: boolean;
  type?: string;
  refetch?: any;
}) {
  const containerRef = useRef(null);
  const [isAdult, setIsAdult] = useState(true);

  const handleIsAdult = useCallback((state: boolean) => {
    setIsAdult(state);
  }, []);

  const handlePickCard = () => {
    if (type == "default") {
      if (paymentMethod.method == "bank") {
        handleAddCardRequest(toast, true);
        close();
        return;
      }
      if (paymentMethod.method == "savedcard") {
        close();
      }
    } else if (type == "activeSub") {
      if (paymentMethod.method == "bank") {
        handleAddBankCardRequest(toast, true);
        close();
        return;
      }
      if (paymentMethod.method == "savedcard") {
        handleActiveSub(toast, paymentMethod.card, refetch);
        close();
      }
    }
  };

  return (
    <>
      <div ref={containerRef}></div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        container={containerRef.current}
        className="flex items-center outline-0"
      >
        <div className="flex flex-col outline-0 w-[492px] sm:w-full sm:h-[100%] sm:max-h-[100%] sm:rounded-none max-h-[610px] h-[95%] overflow-y-auto bg-[#fff] m-auto rounded-[12px] p-[32px] sm:p-4">
          <div className="flex justify-between items-center py-[6px] mb-[15px]">
            <h2 className="font-bold text-[20px] text-blackPrimary-100">
              გადახდის მეთოდები
            </h2>
            <div
              onClick={close}
              className="cursor-pointer w-[44px] h-[44px] bg-gray-30 sm:bg-gray-100 sm:rounded-[8px] rounded-[50%] flex items-center justify-center"
            >
              <ReactSVG src="/images/icons/close.svg" />
            </div>
          </div>
          <div className="mb-[20px]">
            <BankCards
              fromProfile={fromProfile}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
            />
          </div>
          {fromProfile && (
            <div className="flex justify-end mt-auto">
              <Save onClick={handlePickCard} variant="green" label="არჩევა" />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
});
