export const weekendDiscountCardsData = [
  {
    cardId: 1,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/solgar1.svg",
    imageWidth: "153px",
    imageHeight: "126px",
    altText: "solgar",
  },
  {
    cardId: 2,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/amvilab1.svg",
    imageWidth: "153px",
    imageHeight: "126px",
    altText: "amvilab",
  },
  {
    cardId: 3,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/doppelherz.svg",
    imageWidth: "153px",
    imageHeight: "126px",
    altText: "doppel",
  },
  {
    cardId: 4,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/orkla.svg",
    imageWidth: "153px",
    imageHeight: "126px",
    altText: "orkla",
  },
  {
    cardId: 5,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/perrery.svg",
    imageWidth: "153px",
    imageHeight: "126px",
    altText: "perrery",
  },
  {
    cardId: 6,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/AMS.jpg",
    imageWidth: "153px",
    imageHeight: "126px",
    altText: "AMS",
  },
  {
    cardId: 7,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/atisavPharma.png",
    imageWidth: "153px",
    imageHeight: "126px",
    altText: "atisavPharma",
  },
  {
    cardId: 8,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/GMP.jpg",
    imageWidth: "153px",
    imageHeight: "126px",
    altText: "GMP",
  },
  {
    cardId: 9,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/honor.jpg",
    imageWidth: "153px",
    imageHeight: "126px",
    altText: "honor",
  },
  {
    cardId: 10,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/consova.jpg",
    imageWidth: "153px",
    imageHeight: "50px",
    altText: "consova",
  },
  {
    cardId: 11,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/farmitalia.png",
    imageWidth: "153px",
    imageHeight: "50px",
    altText: "farmitalia",
  },
  {
    cardId: 12,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/hankintatukkuoy.png",
    imageWidth: "153px",
    imageHeight: "100px",
    altText: "hankintatukkuoy",
  },
  {
    cardId: 13,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/LGpharma.jpg",
    imageWidth: "153px",
    imageHeight: "40px",
    altText: "LGpharma",
  },
  {
    cardId: 14,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/natheth.png",
    imageWidth: "153px",
    imageHeight: "126px",
    altText: "natheth.png",
  },
  {
    cardId: 15,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/naturalPharma.jpg",
    imageWidth: "153px",
    imageHeight: "126px",
    altText: "naturalPharma",
  },
  {
    cardId: 16,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/nobel.png",
    imageWidth: "153px",
    imageHeight: "126px",
    altText: "nobel",
  },
  {
    cardId: 17,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/olimpLaboratories.png",
    imageWidth: "153px",
    imageHeight: "100px",
    altText: "olimpLaboratories",
  },
  {
    cardId: 18,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/orthomol.jpg",
    imageWidth: "153px",
    imageHeight: "126px",
    altText: "orthomol",
  },
  {
    cardId: 19,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/panin.png",
    imageWidth: "153px",
    imageHeight: "40px",
    altText: "panin",
  },
  {
    cardId: 20,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/pharmanorilacsun.jpg",
    imageWidth: "153px",
    imageHeight: "50px",
    altText: "pharmanorilacsun",
  },
  {
    cardId: 21,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/pileJe.png",
    imageWidth: "153px",
    imageHeight: "80px",
    altText: "pileJe.png",
  },
  {
    cardId: 22,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/polizanoPharmaceuticals.jpg",
    imageWidth: "153px",
    imageHeight: "50px",
    altText: "polizanoPharmaceuticals",
  },
  {
    cardId: 23,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/pure.png",
    imageWidth: "153px",
    imageHeight: "50px",
    altText: "pure",
  },
  {
    cardId: 24,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/quiesserPharma.jpg",
    imageWidth: "153px",
    imageHeight: "126px",
    altText: "quiesserPharma",
  },
  {
    cardId: 25,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/sanience.png",
    imageWidth: "153px",
    imageHeight: "50px",
    altText: "sanience",
  },
  {
    cardId: 26,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/sunlife.png",
    imageWidth: "153px",
    imageHeight: "50px",
    altText: "sunlife",
  },
  {
    cardId: 27,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/psp/triapharmailac.png",
    imageWidth: "153px",
    imageHeight: "100px",
    altText: "triapharmailac",
  },
  {
    cardId: 28,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/vefailac.jpg",
    imageWidth: "153px",
    imageHeight: "126px",
    altText: "vefailac",
  },
  {
    cardId: 29,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/veltPharma.png",
    imageWidth: "153px",
    imageHeight: "50px",
    altText: "veltPharma",
  },
  {
    cardId: 30,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/vitar.jpg",
    imageWidth: "153px",
    imageHeight: "126px",
    altText: "vitar",
  },
  {
    cardId: 31,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/vorvagPharma.png",
    imageWidth: "153px",
    imageHeight: "126px",
    altText: "vorvagPharma",
  },
  {
    cardId: 32,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/walmark.png",
    imageWidth: "153px",
    imageHeight: "126px",
    altText: "walmark",
  },
  {
    cardId: 33,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/wileysFinest.jpg",
    imageWidth: "153px",
    imageHeight: "126px",
    altText: "wileysFinest",
  },
  {
    cardId: 34,
    buttonText: "იხილე მეტი",
    discountNumber: 40,
    imageUrl: "/images/pharmacy/psp/ნეოფარმი.png",
    imageWidth: "153px",
    imageHeight: "126px",
    altText: "ნეოფარმი",
  },
];

export const weekendCardsModalData = [
  {
    id: 1,
    data: [
      {
        id: 1,
        headerText: "SOLGAR",
        discountNumber: 40,
        title: "სოლგარი/C +იმუ კომპლექსი კ.N60",
        altText: "solgar",
        image: "/images/pharmacy/psp/solgar.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 2,
        discountNumber: 40,
        title: "სოლგარი/C ვიტამინი კუბები N90",
        altText: "solgar",
        image: "/images/pharmacy/psp/solgar.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 3,
        discountNumber: 40,
        title: "სოლგარი/ვიტ.D3 10000სე კაფN120",
        altText: "solgar",
        image: "/images/pharmacy/psp/solgar.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 4,
        discountNumber: 40,
        title: "სოლგარი/ვიტ.D3 1000სე კაფსN100",
        altText: "solgar",
        image: "/images/pharmacy/psp/solgar.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 5,
        discountNumber: 40,
        title: "სოლგარი/ვიტ.D3 1000სე სღ.ტN100",
        altText: "solgar",
        image: "/images/pharmacy/psp/solgar.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 6,
        discountNumber: 40,
        title: "სოლგარი/ვიტ.D3 2200სე კაფ.N50",
        altText: "solgar",
        image: "/images/pharmacy/psp/solgar.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 7,
        discountNumber: 40,
        title: "სოლგარი/ვიტ.D3 5000სე წვ.59მლ",
        altText: "solgar",
        image: "/images/pharmacy/psp/solgar.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 8,
        discountNumber: 40,
        title: "სოლგარი/ვიტ.E 400სე კაფს. N50",
        altText: "solgar",
        image: "/images/pharmacy/psp/solgar.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 9,
        discountNumber: 40,
        title: "სოლგარი/თუთია 30მგ კაფს.N100",
        altText: "solgar",
        image: "/images/pharmacy/psp/solgar.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 10,
        discountNumber: 40,
        title: "სოლგარი/თუთია 50მგ ტაბ.N100",
        altText: "solgar",
        image: "/images/pharmacy/psp/solgar.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 11,
        discountNumber: 40,
        title: "სოლგარი/თუთის პიკოლ.22მგ ტN100",
        altText: "solgar",
        image: "/images/pharmacy/psp/solgar.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 12,
        discountNumber: 40,
        title: "სოლგარი/მაგნიუმ+ვიტ.B6 ტაბN100",
        altText: "solgar",
        image: "/images/pharmacy/psp/solgar.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 13,
        discountNumber: 40,
        title: "სოლგარი/მაგნიუმის ციტრატი ტN60",
        altText: "solgar",
        image: "/images/pharmacy/psp/solgar.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 14,
        discountNumber: 40,
        title: "სოლგარი/ომეგა-3 700 კაფს.N60",
        altText: "solgar",
        image: "/images/pharmacy/psp/solgar.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 15,
        discountNumber: 40,
        title: "სოლგარი/ომეგა-3 950 კაფს. N50",
        altText: "solgar",
        image: "/images/pharmacy/psp/solgar.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 16,
        discountNumber: 40,
        title: "სოლგარი/ფრჩხ/თმა/კანი ტაბ. N60",
        altText: "solgar",
        image: "/images/pharmacy/psp/solgar.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 2,
    data: [
      {
        id: 1,
        headerText: "AMVILAB",
        discountNumber: 40,
        title: "ამვილაბი/ეპაკორი კაფს.N30",
        altText: "amvilab",
        image: "/images/pharmacy/psp/amvilab.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 2,
        discountNumber: 40,
        title: "ამვილაბი/ვიტ.D3 2000სე ტაბ.N30",
        altText: "amvilab",
        image: "/images/pharmacy/psp/amvilab.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 3,
        discountNumber: 40,
        title: "ამვილაბი/ვიტ.D3 2000სე ტაბ.N90",
        altText: "amvilab",
        image: "/images/pharmacy/psp/amvilab.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 4,
        discountNumber: 40,
        title: "ამვილაბი/ვიტ.D3 5000სე ტაბ.N30",
        altText: "amvilab",
        image: "/images/pharmacy/psp/amvilab.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 5,
        discountNumber: 40,
        title: "ამვილაბი/ვიტ.D3 საღ.ტ400სე N60",
        altText: "amvilab",
        image: "/images/pharmacy/psp/amvilab.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 6,
        discountNumber: 40,
        title: "ამვილაბი/ვიტ.E 400სე კაფ.N30",
        altText: "amvilab",
        image: "/images/pharmacy/psp/amvilab.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 7,
        discountNumber: 40,
        title: "ამვილაბი/თუთია D3საღ.ტბ15მგN60",
        altText: "amvilab",
        image: "/images/pharmacy/psp/amvilab.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 8,
        discountNumber: 40,
        title: "ამვილაბი/თუთია საღ.ტბ.15მგ N60",
        altText: "amvilab",
        image: "/images/pharmacy/psp/amvilab.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 9,
        discountNumber: 40,
        title: "ამვილაბი/თუთია ტაბ.50მგ N30",
        altText: "amvilab",
        image: "/images/pharmacy/psp/amvilab.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 10,
        discountNumber: 40,
        title: "ამვილაბი/თუთია ტაბ.50მგ N90",
        altText: "amvilab",
        image: "/images/pharmacy/psp/amvilab.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 11,
        discountNumber: 40,
        title: "ამვილაბი/ომეგა 3 კაფს.N90",
        altText: "amvilab",
        image: "/images/pharmacy/psp/amvilab.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 12,
        discountNumber: 40,
        title: "ამვილაბი/ომეგა 3-6-9 ვეგან.N30",
        altText: "amvilab",
        image: "/images/pharmacy/psp/amvilab.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 13,
        discountNumber: 40,
        title: "ამვილაბი/ომეგა 3-6-9 კაფს.N30",
        altText: "amvilab",
        image: "/images/pharmacy/psp/amvilab.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 14,
        discountNumber: 40,
        title: "ამვილაბი/ომეგა 3-6-9 კაფს.N90",
        altText: "amvilab",
        image: "/images/pharmacy/psp/amvilab.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 15,
        discountNumber: 40,
        title: "ამვილაბი/ომეგა 3-6-9 ოკეანეN30",
        altText: "amvilab",
        image: "/images/pharmacy/psp/amvilab.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 16,
        discountNumber: 40,
        title: "ამვილაბი/ომეგა ქიდს საღ.მარN60",
        altText: "amvilab",
        image: "/images/pharmacy/psp/amvilab.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 17,
        discountNumber: 40,
        title: "ამვილაბი/ომეგა-3 კაფს. N30",
        altText: "amvilab",
        image: "/images/pharmacy/psp/amvilab.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 18,
        discountNumber: 40,
        title: "ამვილაბი/სელენი კაფ.200მკგ N30",
        altText: "amvilab",
        image: "/images/pharmacy/psp/amvilab.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 19,
        discountNumber: 40,
        title: "ამვილაბი/სპირულინა კაფს.N60",
        altText: "amvilab",
        image: "/images/pharmacy/psp/amvilab.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 20,
        discountNumber: 40,
        title: "ამვილაბი/ვაშლის ძმარი კაფ.N60",
        altText: "amvilab",
        image: "/images/pharmacy/psp/amvilab.png",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      // {
      //   id: 21,
      //   discountNumber: 40,
      //   title: "ამვილაბი/ვაშლის ძმარი კაფ.N60",
      //   altText: "amvilab",
      //   image: "/images/pharmacy/psp/drug1.svg",
      //   imageWidth: "60px",
      //   imageHeight: "60px",
      // },
      // {
      //   id: 22,
      //   discountNumber: 40,
      //   title: "ამვილაბი/ვაშლის ძმარი კაფ.N60",
      //   altText: "amvilab",
      //   image: "/images/pharmacy/psp/drug1.svg",
      //   imageWidth: "60px",
      //   imageHeight: "60px",
      // },
      // {
      //   id: 23,
      //   discountNumber: 40,
      //   title: "ამვილაბი/ვაშლის ძმარი კაფ.N60",
      //   altText: "amvilab",
      //   image: "/images/pharmacy/psp/drug1.svg",
      //   imageWidth: "60px",
      //   imageHeight: "60px",
      // },
    ],
  },
  {
    id: 3,
    data: [
      {
        id: 1,
        headerText: "DOPPEL HERZ",
        discountNumber: 40,
        title: "დოპელჰერც/აქტ.Mg+B12+D3 პაკN20",
        altText: "doppelherz",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 2,
        discountNumber: 40,
        title: "დოპელჰერც/აქტ.Mg+K პაკ.N20",
        altText: "doppelherz",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 3,
        discountNumber: 40,
        title: "დოპელჰერც/აქტ.ვიტD1000ს.ე.ტN45",
        altText: "doppelherz",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 4,
        discountNumber: 40,
        title: "დოპელჰერც/აქტ.ვიტD2000ს.ე.ტN45",
        altText: "doppelherz",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 5,
        discountNumber: 40,
        title: "დოპელჰერც/აქტ.მაგნ.500მგ2ფ.N30",
        altText: "doppelherz",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 6,
        discountNumber: 40,
        title: "დოპელჰერც/აქტ.მემორ.ომეგა3 N30",
        altText: "doppelherz",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 7,
        discountNumber: 40,
        title: "დოპელჰერც/აქტ.ომეგა-3 კაფ N30",
        altText: "doppelherz",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 8,
        discountNumber: 40,
        title: "დოპელჰერც/აქტ.ომეგა-3 სირ150მლ",
        altText: "doppelherz",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 9,
        discountNumber: 40,
        title: "დოპელჰერც/აქტ.ფლუ სტოპი შ.ტN15",
        altText: "doppelherz",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 10,
        discountNumber: 40,
        title: "დოპელჰერც/სისტ.D3+K2 ტაბ.N30",
        altText: "doppelherz",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 11,
        discountNumber: 40,
        title: "დოპელჰერც/სისტ.Mg 400მგ პაკN20",
        altText: "doppelherz",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 12,
        discountNumber: 40,
        title: "დოპელჰერც/სისტ.Mg 400მგ ტაბN24",
        altText: "doppelherz",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 13,
        discountNumber: 40,
        title: "დოპელჰერც/სისტ.იმუნი სირ.250მლ",
        altText: "doppelherz",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 4,
    data: [
      {
        id: 1,
        headerText: "ORKLA",
        discountNumber: 40,
        title: "ომეგა 3 მიოლერი ვაშლი 250მლ",
        altText: "orkla",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 2,
        discountNumber: 40,
        title: "ომეგა 3 მიოლერი ლიმონი 250მლ",
        altText: "orkla",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 3,
        discountNumber: 40,
        title: "ომეგა 3 მიოლერი ომეგაკორი კN60",
        altText: "orkla",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 4,
        discountNumber: 40,
        title: "ომეგა 3 მიოლერი ფორტე კაფ.N150",
        altText: "orkla",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 5,
        discountNumber: 40,
        title: "ომეგა 3 მიოლერი შერ.ხილი 250მლ",
        altText: "orkla",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 6,
        discountNumber: 40,
        title: "ომეგა 3 მიოლერი ხილის ჟელე N45",
        altText: "orkla",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 5,
    data: [
      {
        id: 1,
        headerText: "Perrery Farmaceutici s.r.l",
        discountNumber: 40,
        title: "დევივა D3 წვეთი 10მლ",
        altText: "perrery",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 6,
    data: [
      {
        id: 1,
        headerText: "AMS",
        discountNumber: 40,
        title: "გრავიდა D3 კაფს. N90",
        altText: "ams",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 2,
        discountNumber: 40,
        title: "გრავიდა მაგნეზიუმი კაფ.70მგN90",
        altText: "ams",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 7,
    data: [
      {
        id: 1,
        headerText: "ATISAV PHARMA",
        discountNumber: 40,
        title: "პანდევიტი K წვეთები 10მლ",
        altText: "atisav",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 8,
    data: [
      {
        id: 1,
        headerText: "GMP",
        discountNumber: 40,
        title: "NAT/მულტი ომეგა კაფსულა N30",
        altText: "gmp",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 9,
    data: [
      {
        id: 1,
        headerText: "HONOR",
        discountNumber: 40,
        title: "მეგაშელზი ტაბ.N10+კაფს.N10",
        altText: "honor",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 10,
    data: [
      {
        id: 1,
        headerText: "COSNOVA",
        discountNumber: 40,
        title: "ინაო/იმუნიტეტი საღ.ტაბ.N60",
        altText: "cosnova",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 11,
    data: [
      {
        id: 1,
        headerText: "FARMITALIA",
        discountNumber: 40,
        title: "მულტიფოლიკო DHA კაფ.N30+30",
        altText: "farmitalia",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 12,
    data: [
      {
        id: 1,
        headerText: "HANKINTATUKKU OY",
        discountNumber: 40,
        title: "ვიტ.D3 2000სე HNK ტაბ. N60",
        altText: "HANKINTATUKKU OY",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 2,
        headerText: "HANKINTATUKKU OY",
        discountNumber: 40,
        title: "მაგნე+B6+B1+B3 HNK ტაბ. N60",
        altText: "HANKINTATUKKU OY",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 3,
        headerText: "HANKINTATUKKU OY",
        discountNumber: 40,
        title: "სელენ-200 ტაბ.200მკგ N30 Hank",
        altText: "HANKINTATUKKU OY",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 13,
    data: [
      {
        id: 1,
        headerText: "LJ PHARMA S.R.L",
        discountNumber: 40,
        title: "მულტიფოლიკო კაფს.N60",
        altText: "lgchem",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 14,
    data: [
      {
        id: 1,
        headerText: "NAT HEALTH",
        discountNumber: 40,
        title: "NAT/მულტი ომეგა კაფსულა N30",
        altText: "nathealth",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 15,
    data: [
      {
        id: 1,
        headerText: "NATURAL PHARMA",
        discountNumber: 40,
        title: "მოდევიტი სიროფი 200მლ",
        altText: "naturalpharma",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 2,
        headerText: "NATURAL PHARMA",
        discountNumber: 40,
        title: "ცინკო კიდსი სიროფი 100მლ",
        altText: "naturalpharma",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 16,
    data: [
      {
        id: 1,
        headerText: "NOBEL (TR)",
        discountNumber: 40,
        title: "ნბლ ვიტ.D3 წვეთები 7.5მლ *",
        altText: "nobel",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 17,
    data: [
      {
        id: 1,
        headerText: "OLIMP LABORATORIES",
        discountNumber: 40,
        title: "გოლდ ვიტამინი D3 ტაბ4000სე N30",
        altText: "olimplabs",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 2,
        discountNumber: 40,
        title: "სელენი ოლიმპი 110მკგ ტაბ.N120",
        altText: "olimplabs",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 3,
        discountNumber: 40,
        title: "ხელა მაგ B6 ჯუნიორი პაკ.N15",
        altText: "olimplabs",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 18,
    data: [
      {
        id: 1,
        headerText: "ORTHOMOL GMBH",
        discountNumber: 40,
        title: "ორთომოლი მაგნიუმი+ კაფ.N60",
        altText: "orthomol",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 19,
    data: [
      {
        id: 1,
        headerText: "PANIN S.R.L",
        discountNumber: 40,
        title: "ფილმე ოლიო რექტალ.გელი 30მლ",
        altText: "panin",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 2,
        discountNumber: 40,
        title: "ფილმე ჯინო V-6 ვაგ.კაფს.N6",
        altText: "panin",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 20,
    data: [
      {
        id: 1,
        headerText: "PHARMANOR ILAC",
        discountNumber: 40,
        title: "ნანო ცინკდევიტი სიროფი120 მლ",
        altText: "pharmanorilac",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 21,
    data: [
      {
        id: 1,
        headerText: "PILEJE",
        discountNumber: 40,
        title: "პლჟ/ვიტ. D3 ბიენი 20მლ",
        altText: "pileje",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 2,
        headerText: "PILEJE",
        discountNumber: 40,
        title: "პლჟ/ფორმაგი MG ტაბ.N30",
        altText: "pileje",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 22,
    data: [
      {
        id: 1,
        headerText: "POLISANO PHARMACEUTICALS S.R.L",
        discountNumber: 40,
        title: "ჰელიომაგნე+B6 ტაბ.N60",
        altText: "polisano",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 23,
    data: [
      {
        id: 1,
        headerText: "PURE",
        discountNumber: 40,
        title: "პიური/ვიტ.D3 5000სე კაფ.N60",
        altText: "pure",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 2,
        discountNumber: 40,
        title: "პიური/თუთია 15 კაფს.N60",
        altText: "pure",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 3,
        discountNumber: 40,
        title: "პიური/მაგნიუმის გლიცინ.კაფ.N90",
        altText: "pure",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 4,
        discountNumber: 40,
        title: "პიური/მაგნიუმის გლიცინ.კაფN180",
        altText: "pure",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 5,
        discountNumber: 40,
        title: "პიური/სელენიუმი კაფსულა N60",
        altText: "pure",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 24,
    data: [
      {
        id: 1,
        headerText: "QUEISSER PHARMA",
        discountNumber: 40,
        title: "დოპელჰერც/აქტ.Mg+B12+D3 პაკN20",
        altText: "quesser",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 2,
        discountNumber: 40,
        title: "დოპელჰერც/აქტ.Mg+K პაკ.N20",
        altText: "quesser",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 3,
        discountNumber: 40,
        title: "დოპელჰერც/აქტ.ვიტD1000ს.ე.ტN45",
        altText: "quesser",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 4,
        discountNumber: 40,
        title: "დოპელჰერც/აქტ.ვიტD2000ს.ე.ტN45",
        altText: "quesser",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 5,
        discountNumber: 40,
        title: "დოპელჰერც/აქტ.მაგნ.500მგ2ფ.N30",
        altText: "quesser",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 6,
        discountNumber: 40,
        title: "დოპელჰერც/აქტ.მემორ.ომეგა3 N30",
        altText: "quesser",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 7,
        discountNumber: 40,
        title: "დოპელჰერც/აქტ.ომეგა-3 კაფ N30",
        altText: "quesser",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 8,
        discountNumber: 40,
        title: "დოპელჰერც/აქტ.ომეგა-3 სირ150მლ",
        altText: "quesser",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 9,
        discountNumber: 40,
        title: "დოპელჰერც/აქტ.ფლუ სტოპი შ.ტN15",
        altText: "quesser",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 10,
        discountNumber: 40,
        title: "დოპელჰერც/სისტ.D3+K2 ტაბ.N30",
        altText: "quesser",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 11,
        discountNumber: 40,
        title: "დოპელჰერც/სისტ.Mg 400მგ პაკN20",
        altText: "quesser",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 12,
        discountNumber: 40,
        title: "დოპელჰერც/სისტ.Mg 400მგ ტაბN24",
        altText: "quesser",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 13,
        discountNumber: 40,
        title: "დოპელჰერც/სისტ.იმუნი სირ.250მლ",
        altText: "quesser",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 25,
    data: [
      {
        id: 1,
        headerText: "SANIENCE S.R.L",
        discountNumber: 40,
        title: "სანიენსი D3 სპრ.250დოზა 50მლ",
        altText: "sanience",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 26,
    data: [
      {
        id: 1,
        headerText: "Sunlife",
        discountNumber: 40,
        title: "ვიტამინი C1000მგ+Zn10მგ შ.ტN20",
        altText: "sunlife",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 2,
        discountNumber: 40,
        title: "თუთია შუშხუნა ტაბ. 20მგ N20",
        altText: "sunlife",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 3,
        discountNumber: 40,
        title: "სანლაიფი D3 1000სე ტაბ.N60",
        altText: "sunlife",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 4,
        discountNumber: 40,
        title: "სანლაიფი ომეგასანი ქიდსი 200მლ",
        altText: "sunlife",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 5,
        discountNumber: 40,
        title: "დეტრივიტი დროფსი 30მლ",
        altText: "sunlife",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 27,
    data: [
      {
        id: 1,
        headerText: "Triapharma ilac Sanayi",
        discountNumber: 40,
        title: "ვიტომეგა სიროფი 100მლ",
        altText: "triapharma",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 28,
    data: [
      {
        id: 1,
        headerText: "Vefa ILAC",
        discountNumber: 40,
        title: "ნბლ თევზის ქონი JR. 150მლ",
        altText: "vefailac",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 2,
        discountNumber: 40,
        title: "სელენეტი ტაბ. N45",
        altText: "vefailac",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 29,
    data: [
      {
        id: 1,
        headerText: "WELT PHARMA",
        discountNumber: 40,
        title: "დეტრივიტი მაქსი კაფ10000IU N30",
        altText: "weltpharma",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 2,
        discountNumber: 40,
        title: "დეტრივიტი ჟელი N50",
        altText: "weltpharma",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 3,
        discountNumber: 40,
        title: "ნექსი B6 ტაბ.400მგ/2.8მგ N30",
        altText: "weltpharma",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 4,
        discountNumber: 40,
        title: "ნექსი ნაითი ტაბ.N30",
        altText: "weltpharma",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 5,
        discountNumber: 40,
        title: "ომესამი D3 კაფს.2000ს.ე.N20",
        altText: "weltpharma",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 6,
        discountNumber: 40,
        title: "ომესამი კაფს.1000მგ N20",
        altText: "weltpharma",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 7,
        discountNumber: 40,
        title: "ომესამი ჯუნიორი საღ.კ.850მგN20",
        altText: "weltpharma",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 8,
        discountNumber: 40,
        title: "ცინკოვიდი ნეირო შუშხ.ტაბ.N20",
        altText: "weltpharma",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 9,
        discountNumber: 40,
        title: "ცინკუს-15 ტაბ.N30",
        altText: "weltpharma",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 10,
        discountNumber: 40,
        title: "ვიტ. C შუშხ.ტაბ.1000მგ N20 ლიმ",
        altText: "weltpharma",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 11,
        discountNumber: 40,
        title: "ვიტ. C შუშხ.ტაბ.1000მგ N20 ჟოლ",
        altText: "weltpharma",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 12,
        discountNumber: 40,
        title: "ვიტ. C შუშხ.ტაბ.1000მგ N20 ფორ",
        altText: "weltpharma",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 13,
        discountNumber: 40,
        title: "ვიტ. C შუშხ.ტაბ.1000მგ N20გარგ",
        altText: "weltpharma",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 30,
    data: [
      {
        id: 1,
        headerText: "VITAR S.R.O",
        discountNumber: 40,
        title: "დოქტორ ნემო საღ.კაფს. N30",
        altText: "vitar",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 31,
    data: [
      {
        id: 1,
        headerText: "Worwag Pharma",
        discountNumber: 40,
        title: "ვიტაგამა D3 2000სე ტაბ.N100",
        altText: "worwag",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 2,
        discountNumber: 40,
        title: "ვიტაგამა D3 2000სე ტაბ.N50",
        altText: "worwag",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 3,
        discountNumber: 40,
        title: "ვიტაგამა D3 5600სე ტაბ.N20",
        altText: "worwag",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 4,
        discountNumber: 40,
        title: "ვიტაგამა D3 5600სე ტაბ.N50",
        altText: "worwag",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 5,
        discountNumber: 40,
        title: "ვიტაგამა D3 დუო ტაბ.N50",
        altText: "worwag",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 32,
    data: [
      {
        id: 1,
        headerText: "WALMARK",
        discountNumber: 40,
        title: "ვიტრუმი ვიტ.C საღ.ტაბ.ფორთ.N30",
        altText: "walmark",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 2,
        discountNumber: 40,
        title: "კარდიომ ომეგა-3 ფორტე1000კ.N30",
        altText: "walmark",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 3,
        discountNumber: 40,
        title: "კარდიომ ომეგა-3 ფორტე1000კ.N60",
        altText: "walmark",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 33,
    data: [
      {
        id: 1,
        headerText: "WILEYS FINEST",
        discountNumber: 40,
        title: "ალასკან ომეგა-3 კაფს.N60",
        altText: "wileysfinest",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 2,
        discountNumber: 40,
        title: "ბიგინერს დეჰა სუსპ.125მლ",
        altText: "wileysfinest",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
      {
        id: 3,
        discountNumber: 40,
        title: "ფულ სპექტრუმ ომეგა-3 კაფს.N60",
        altText: "wileysfinest",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
  {
    id: 34,
    data: [
      {
        id: 1,
        headerText: "ნეოფარმი",
        discountNumber: 40,
        title: "ვიტ. C ტაბ. 0.1 N40",
        altText: "ნეოფარმი",
        image: "/images/pharmacy/psp/drug1.svg",
        imageWidth: "60px",
        imageHeight: "60px",
      },
    ],
  },
];

export const medicamentsWithCat = <any>{
  face: [
    {
      id: 1,
      headerText: "CAUDALIE",
      title: "კოდალი",
      discount: "-35",
      image: "/images/pharmacy/psp/caudalie1.svg",
      imageWidth: "100px",
      imageHeight: "100px",
    },
    {
      id: 2,
      headerText: "MISSHA",
      title: "მისშა",
      discount: "-35",
      image: "/images/pharmacy/psp/missha.svg",
      imageWidth: "100px",
      imageHeight: "100px",
    },
    {
      id: 3,
      headerText: "MIZON",
      title: "მიზონი",
      discount: "-35",
      image: "/images/pharmacy/psp/mizon.svg",
      imageWidth: "100px",
      imageHeight: "100px",
    },

    {
      id: 4,
      headerText: "LA ROCHE POSAY",
      title: "ლა როშე პოზე",
      discount: "-35",
      image: "/images/pharmacy/psp/La roche Posay logo.png",
      imageWidth: "100px",
      imageHeight: "100px",
    },

    {
      id: 5,
      headerText: "ESTEDERM",
      title: "ესტედერმი",
      discount: "-35",
      image: "/images/pharmacy/psp/esthederm.svg",
      imageWidth: "100px",
      imageHeight: "100px",
    },
    {
      id: 6,
      headerText: "SVR",
      title: "ესვიერი",
      discount: "-35",
      image: "/images/pharmacy/psp/svr.svg",
      imageWidth: "100px",
      imageHeight: "100px",
    },
  
    {
      id: 7,
      headerText: "Sebamed",
      title: "სებამედი",
      discount: "-35",
      image: "/images/pharmacy/psp/sebamed logo.jpg",
      imageWidth: "100px",
      imageHeight: "100px",
    },
    {
      id: 8,
      headerText: "Topicream",
      title: "ტოპიკრემი",
      discount: "-35",
      image: "/images/pharmacy/psp/topicrem-logo.png",
      imageWidth: "100px",
      imageHeight: "100px",
    },
    {
      id: 19,
      headerText: "Ziaja",
      title: "ზიაია",
      discount: "-35",
      image: "/images/pharmacy/psp/Ziaja.jpg",
      imageWidth: "100px",
      imageHeight: "100px",
    },


    {
      id: 10,
      headerText: "LE PETIT OLIVIER",
      title: "ლე პეტიტ ოლივიე",
      discount: "-35",
      image: "/images/pharmacy/psp/lePetitOlivier.svg",
      imageWidth: "100px",
      imageHeight: "100px",
    },
    {
      id: 11,
      headerText: "BIODERMA",
      title: "ბიოდერმა",
      discount: "-35",
      image: "/images/pharmacy/psp/bioderma.svg",
      imageWidth: "100px",
      imageHeight: "100px",
    },

    {
      id: 12,
      headerText: "Echosline",
      title: "ეკოსლაინი",
      discount: "-35",
      image: "/images/pharmacy/psp/echosline logo.png",
      imageWidth: "100px",
      imageHeight: "100px",
    },
    {
      id: 13,
      headerText: "Jugavi",
      title: "ჯუგავი",
      discount: "-35",
      image: "/images/pharmacy/psp/Jugavi.png",
      imageWidth: "100px",
      imageHeight: "100px",
    },
    {
      id: 14,
      headerText: "LEA",
      title: "ლეა",
      discount: "-35",
      image: "/images/pharmacy/psp/Lea.png",
      imageWidth: "100px",
      imageHeight: "100px",
    },
    {
      id: 15,
      headerText: "ALIX AVIEN",
      title: "ალიქს ავიენ",
      discount: "-35",
      image: "/images/pharmacy/psp/alixavien.svg",
      imageWidth: "100px",
      imageHeight: "100px",
    },
    {
      id: 16,
      headerText: "BIO_ROM",
      title: "ბიორომი",
      discount: "-35",
      image: "/images/pharmacy/psp/biorom.svg",
      imageWidth: "100px",
      imageHeight: "100px",
    },
    {
      id: 17,
      headerText: "MARTI DERM",
      title: "მარტი დერმი",
      discount: "-35",
      image: "/images/pharmacy/psp/martiderm.svg",
      imageWidth: "100px",
      imageHeight: "100px",
    },
    {
      id: 18,
      headerText: "Dr.Irena Eris",
      title: "დრ. ირენა ირისი",
      discount: "-35",
      image: "/images/pharmacy/psp/dr.irena.png",
      imageWidth: "100px",
      imageHeight: "100px",
    },
    {
      id: 19,
      headerText: "ALGOLOGIE",
      title: "ალგოლოგი",
      discount: "-35",
      image: "/images/pharmacy/psp/algologie.svg",
      imageWidth: "100px",
      imageHeight: "100px",
    },

    {
      id: 20,
      headerText: "NIPPES Solingen",
      title: "ნიპეს ზოლინგენი",
      discount: "-35",
      image: "/images/pharmacy/psp/nippes.jpg",
      imageWidth: "100px",
      imageHeight: "100px",
    },
 
  
  ],
  mouth: [
    {
      id: 1,
      headerText: "PIAVE",
      title: "პიავე",
      discount: "-35",
      image: "/images/pharmacy/psp/piave.svg",
      imageWidth: "100px",
      imageHeight: "100px",
    },
    {
      id: 2,
      headerText: "BIOREPAIR",
      title: "ბიოირეპაირი",
      discount: "-35",
      image: "/images/pharmacy/psp/biorepair.svg",
      imageWidth: "100px",
      imageHeight: "100px",
    },
    {
      id: 3,
      headerText: "curasept",
      title: "კურასეპტი",
      discount: "-35",
      image: "/images/pharmacy/psp/curasept.png",
      imageWidth: "100px",
      imageHeight: "100px",
    },
    {
      id: 4,
      headerText: "BLANX",
      title: "ბლენქსი",
      discount: "-35",
      image: "/images/pharmacy/psp/blanx.svg",
      imageWidth: "100px",
      imageHeight: "100px",
    },
  ],
  hair: [
    {
      id: 1,
      headerText: "KAYPRO",
      title: "კეიპრო",
      discount: "-35",
      image: "/images/pharmacy/psp/kaypro.svg",
      imageWidth: "100px",
      imageHeight: "100px",
    },
    {
      id: 2,
      headerText: "ALIX",
      title: "ალექს",
      discount: "-35",
      image: "/images/pharmacy/psp/alix.jpg",
      imageWidth: "100px",
      imageHeight: "100px",
    },
    {
      id: 3,
      headerText: "DALIN",
      title: "დალინი",
      discount: "-35",
      image: "/images/pharmacy/psp/dalin-1.webp",
      imageWidth: "100px",
      imageHeight: "100px",
    },
  
  ],
};

export const proteinsCardsData = [
  {
    cardId: 1,
    imageUrl: "/images/pharmacy/psp/protein1.svg",
    imageWidth: "222px",
    imageHeight: "300px",
    altText: "protein1",
    discountNumber: 20,
    description: "NS/პროტეინი100% შოკ/ბრ900გ2839",
  },
  {
    cardId: 2,
    imageUrl: "/images/pharmacy/psp/protein2.svg",
    imageWidth: "222px",
    imageHeight: "300px",
    altText: "protein2",
    discountNumber: 20,
    description: "NS/აცეტილ L-კარნიტინი თხევ0095",
  },
  {
    cardId: 3,
    imageUrl: "/images/pharmacy/psp/protein3.svg",
    imageWidth: "222px",
    imageHeight: "300px",
    altText: "protein3",
    discountNumber: 20,
    description: "NS/პროტეინი100% კრემ.900გ 2808",
  },
  {
    cardId: 4,
    imageUrl: "/images/pharmacy/psp/protein4.svg",
    imageWidth: "222px",
    imageHeight: "300px",
    altText: "protein4",
    discountNumber: 20,
    description: "NS/ამინო16 პრო პაკ.N30 0897",
  },
  {
    cardId: 5,
    imageUrl: "/images/pharmacy/psp/protein5.jpg",
    imageWidth: "222px",
    imageHeight: "300px",
    altText: "protein5",
    discountNumber: 20,
    description: "L-კარნიტინი ანან.500მლ 1955",
  },
  {
    cardId: 6,
    imageUrl: "/images/pharmacy/psp/protein6.jpg",
    imageWidth: "222px",
    imageHeight: "300px",
    altText: "protein6",
    discountNumber: 20,
    description: "ენერჯი ამინო გელი 50მლ 0033",
  },
  {
    cardId: 7,
    imageUrl: "/images/pharmacy/psp/protein7.jpg",
    imageWidth: "222px",
    imageHeight: "300px",
    altText: "protein7",
    discountNumber: 20,
    description: "ენერჯიბერი ველ.კენკ35გ 3508",
  },
  {
    cardId: 8,
    imageUrl: "/images/pharmacy/psp/protein8.jpg",
    imageWidth: "222px",
    imageHeight: "300px",
    altText: "protein8",
    discountNumber: 20,
    description: "ენერჯიბერი მოცვ.თხ.35გ",
  },
  {
    cardId: 9,
    imageUrl: "/images/pharmacy/psp/protein9.jpg",
    imageWidth: "222px",
    imageHeight: "300px",
    altText: "protein9",
    discountNumber: 20,
    description: "მაგნიუმი თხ+ვიტB6 25მლ 2136",
  },
  {
    cardId: 10,
    imageUrl: "/images/pharmacy/psp/protein10.jpg",
    imageWidth: "120px",
    imageHeight: "180px",
    altText: "protein10",
    discountNumber: 20,
    description: "მულტივიტამ&მინერალ.N30 1702",
  },
];

export const daysList = [
  {
    day: "ორშაბათი",
    isVita: true,
    img: "/images/pharmacy/vita.svg",
  },
  {
    day: "სამშაბათი",
    isVita: false,
    img: "/images/pharmacy/psp.svg",
  },
  {
    day: "ოთხშაბათი",
    isVita: false,
    img: "/images/pharmacy/psp.svg",
  },
  {
    day: "ხუთშაბათი",
    isVita: true,
    img: "/images/pharmacy/vita.svg",
  },
  {
    day: "პარასკევი",
    isVita: false,
    img: "/images/pharmacy/psp.svg",
  },
  {
    day: "შაბათი",
    isVita: true,
    img: "/images/pharmacy/vita.svg",
  },
  {
    day: "კვირა",
    isVita: true,
    img: "/images/pharmacy/vita.svg",
  },
];

export const cats = [
  {
    img: "/images/pharmacy/caticons/1.svg",
    title: "კარდიოლოგია, ანგიოლოგია",
    discount: "5%- 50% მდე",
  },
  {
    img: "/images/pharmacy/caticons/2.svg",
    title: "გასტროენტეროლოგია",
    discount: "5%- 50% მდე",
  },
  {
    img: "/images/pharmacy/caticons/3.svg",
    title: "ნევროლოგია",
    discount: "5%- 50% მდე",
  },
  {
    img: "/images/pharmacy/caticons/4.svg",
    title: "ანთების საწინააღმდეგო და ტკივილგამაყუჩებლები",
    discount: "5%- 50% მდე",
  },
  {
    img: "/images/pharmacy/caticons/5.svg",
    title: "ოფთალმოლოგია",
    discount: "5%- 50% მდე",
  },
];

export const medicaments = [
  {
    img: "/images/pharmacy/medicaments/amvilab.png",
    title: "AMVILAB",
    title2: "ამვილაბი",
    discount: "-35%",
  },
  {
    img: "/images/pharmacy/medicaments/doopel.png",
    title: "DOPPEL HERZ",
    title2: "დოპელ ჰერც",
    discount: "-35%",
  },
  {
    img: "/images/pharmacy/medicaments/pileje.png",
    title: "PiLeJe",
    title2: "პილეჟე",
    discount: "-35%",
  },
  {
    img: "/images/pharmacy/medicaments/solgar.png",
    title: "SOLGAR",
    title2: "სოლგარი",
    discount: "-35%",
  },
];

export let categories = [
  {
    id: 1,
    slug: "face",
    title: "სახე",
    img: "/images/pharmacy/face.svg",
  },
  {
    id: 2,
    title: "კბილები",
    slug: "mouth",
    img: "/images/pharmacy/teeth.svg",
  },
  {
    id: 3,
    title: "თმა",
    slug: "hair",
    img: "/images/pharmacy/hair.svg",
  },
];

export const Seasonalmedications = {
  "გაციების ჩაი": [
    {
      img :"/images/pspMedicine/coldrex_maxgrip_lemone.jpg",
      medicine: "კოლდრექსი მაქსიგრიპი ლიმ. N10",
      price: 24.10,
      sale: 40,
      salePrice: 14.46,
    },
    {
      img :"/images/pspMedicine/coldrex_maxgrip_lemone_honey.jpg",
      medicine: "კოლდრექსი ჰ.თაფლ. და ლიმონ.N10",
      price: 19.63,
      sale: 40,
      salePrice: 11.78,
    },
    {
      img : "/images/pspMedicine/teraflue_extra.jpg",
      medicine: "ტერაფლუ ექსტრა პაკ.N10",
      price: 24.29,
      sale: 45,
      salePrice: 13.36,
    },
    {
      img :"/images/pspMedicine/teraflue_pack.jpg",
      medicine: "ტერაფლუ პაკეტი N10",
      price: 23.79,
      sale: 45,
      salePrice: 13.08,
    },
    {
      img : "/images/pspMedicine/coldrex_hotrem.jpg",
      medicine: "კოლდრექსი ჰოტრემი ლიმონით N10",
      price: 19.81,
      sale: 40,
      salePrice: 11.89,
    },
    {
      img :"/images/pspMedicine/antiflue_kids_n5.jpg",
      medicine: "ანტიფლუ კიდსი პაკ.N5 ჟოლოს",
      price: 17.75,
      sale: 40,
      salePrice: 10.65,
    },
    {
      img :"/images/pspMedicine/anitflue_n5_lemone.jpg",
      medicine: "ანტიფლუ პაკ. N5 ლიმონით",
      price: 18.36,
      sale: 40,
      salePrice: 11.02,
    },
    {
      img :"/images/pspMedicine/griphot_n12.jpg",
      medicine: "გრიპ ჰოთი N12 პაკეტი",
      price: 21.86,
      sale: 45,
      salePrice: 12.02,
    },
    {
      img : "/images/pspMedicine/fervex_sugar_free.jpg",
      medicine: "ფერვექსი უშაქრო გრან.N8",
      price: 14.59,
      sale: 30,
      salePrice: 10.21,
    },
    {
      img : "/images/pspMedicine/fervex_sugar.jpg",
      medicine: "ფერვექსი შაქრით გრან.N8",
      price: 12.72,
      sale: 30,
      salePrice: 8.91,
    },
    {
      img : "/images/pspMedicine/fervex_jolo_sugar.jpg",
      medicine: "ფერვექსი შაქრით გრან.N8 ჟოლო",
      price: 17.69,
      sale: 30,
      salePrice: 12.38,
    },
  ],
  "სპრეი და ჩასაწვეთებელი": [
    {
      img :"/images/pspMedicine/benet-eco-15ml.jpg",
      medicine: "ბენეტი ეკო სპრეი 0.15% 15მლ",
      price: 13.07,
      sale: 40,
      salePrice: 7.84,
    },
    {
      img :"/images/pspMedicine/benet30.jpg",
      medicine: "ბენეტი სპრეი 30მლ",
      price: 21.63,
      sale: 40,
      salePrice: 12.98,
    },
    {
      img : "/images/pspMedicine/glazoline_01.jpg",
      medicine: "გალაზოლინი 0,1% 10მლ",
      price: 6.51,
      sale: 35,
      salePrice: 4.23,
    },
    {
      img :"/images/pspMedicine/nazoline_15.jpg",
      medicine: "ნაზოლი სპრეი 15მლ 0.5მგ/მლ",
      price: 14.31,
      sale: 40,
      salePrice: 8.59,
    },
    {
      img :"/images/pspMedicine/nazol_30.jpg",
      medicine: "ნაზოლი სპრეი 30მლ 0.5მგ/მლ",
      price: 21.24,
      sale: 40,
      salePrice: 12.74,
    },
    {
      img :"/images/pspMedicine/otrivin_005.jpg",
      medicine: "ოტრივინი 0.05% 10მლ ცხვ.წვ.",
      price: 12.68,
      sale: 40,
      salePrice: 7.61,
    },
    {
      img :"/images/pspMedicine/otrivin_01.jpg",
      medicine: "ოტრივინი 0.1% 10მლ ცხვ.აეროზ.",
      price: 13.35,
      sale: 40,
      salePrice: 8.01,
    },
    {
      img : "/images/pspMedicine/otrivin_ment.jpg",
      medicine: "ოტრივინი მენთ/ევკ.სპრ0.1% 10მლ",
      price: 14.70,
      sale: 40,
      salePrice: 8.82,
    },
    {
      img : "/images/pspMedicine/snop45.jpg",
      medicine: "სნუპი სპრეი 45მკგ/დოზა 15მლ",
      price: 8.25,
      sale: 40,
      salePrice: 4.95,
    },
    {
      img : "/images/pspMedicine/snop90.jpg",
      medicine: "სნუპი სპრეი 90მკგ/დოზა 15მლ",
      price: 8.15,
      sale: 40,
      salePrice: 4.89,
    },
    {     
      img : "/images/pspMedicine/qsilo005.jpg",
      medicine: "ქსილომარინი სპრ.0.05% 10მლ GMP",
      price: 9.16,
      sale: 35,
      salePrice: 5.95,
    },
    {
      img : "/images/pspMedicine/qsilo005.jpg",
      medicine: "ქსილომარინი სპრ.0.1% 10მლ GMP",
      price: 9.90,
      sale: 35,
      salePrice: 6.44,
    },
  ],
  "ტაბლეტები და კაფსულები": [
    {
      img : "/images/pspMedicine/antiflue_tab.jpg",
      medicine: "ანტიფლუ ტაბ. N12",
      price: 15.73,
      sale: 40,
      salePrice: 9.44,
    },
    {
      img : "/images/pspMedicine/acc600.jpg",
      medicine: "აცც ექსტრა 600მგ შუშხ.ტაბ.N10",
      price: 16.11,
      sale: 40,
      salePrice: 9.67,
    },
    {      
      img : "/images/pspMedicine/bromhex_tab.jpg",
      medicine: "ბრომჰექსინი ტაბ.8მგ N25",
      price: 3.77,
      sale: 35,
      salePrice: 2.45,
    },
    {
      img : "/images/pspMedicine/bron7.jpg",
      medicine: "ბრონხომუნალი კაფს. 7მგ.N10",
      price: 41.34,
      sale: 35,
      salePrice: 26.87,
    },
    {
      img : "/images/pspMedicine/bron_3.5.jpg",
      medicine: "ბრონხომუნალი-P კაფს. 3,5მგ.N10",
      price: 30.29,
      sale: 35,
      salePrice: 19.69,
    },
    {
      img : "/images/pspMedicine/vit_jolo.jpg",
      medicine: "ვიტ. C შუშხ.ტაბ.1000მგ N20 ჟოლ",
      price: 8.20,
      sale: 30,
      salePrice: 5.74,
    },
    {
      img : "/images/pspMedicine/ismijen_10.jpg",
      medicine: "ისმიჟენი ტაბ. N10 GMP",
      price: 38.11,
      sale: 35,
      salePrice: 24.77,
    },
    {
      img : "/images/pspMedicine/panadol_extra.jpg",
      medicine: "პანადოლი ექსტრა ტაბ.N24 თრ",
      price: 4.93,
      sale: 35,
      salePrice: 3.21,
    },
    {
      img : "/images/pspMedicine/panadol.jpg",
      medicine: "პანადოლი ტაბ.N12",
      price:4.20,
      sale: 35,
      salePrice: 2.73,
    },
    {
      img : "/images/pspMedicine/teraflue_max.jpg",
      medicine: "ტერაფლუ მაქსტაბი ტაბ.N12",
      price: 11.53,
      sale: 35,
      salePrice: 7.50,
    },
    {
      img : "/images/pspMedicine/teraflue_forte.jpg",
      medicine: "ტერაფლუ ფორტე ტაბ.N20 თრ",
      price: 5,
      sale: 20,
      salePrice: 4,
    },
    {
      img : "/images/pspMedicine/farcoslovin.jpg",
      medicine: "ფარკოსოლვინი კაფს. N24",
      price: 13.74,
      sale: 40,
      salePrice: 8.24,
    },
  ],
  "სანთლები და პლასტირები": [
    {
      img : "/images/pspMedicine/efer150.jpg",
      medicine: "ეფერალგანი სანთ. 150მგ N10",
      price: 7.40,
      sale: 35,
      salePrice: 4.81,
    },
    {
      img : "/images/pspMedicine/efer300.jpg",
      medicine: "ეფერალგანი სანთ. 300მგ N10",
      price: 7.41,
      sale: 35,
      salePrice: 4.82,
    },
    {
      img : "/images/pspMedicine/efer80.jpg",
      medicine: "ეფერალგანი სანთ. 80მგ N10",
      price: 6.06,
      sale: 35,
      salePrice: 3.94,
    },
    {
      img : "/images/pspMedicine/ais_plastir.jpg",
      medicine: "აის ქულინგი პლასტირი N5",
      price: 13.45,
      sale: 50,
      salePrice: 6.72,
    },
  ],
  "სიროფი": [
    {
      img : "/images/pspMedicine/sinekod100.jpg",
      medicine: "სინეკოდი სიროფი 100მლ თრ",
      price: 9.80,
      sale: 30,
      salePrice: 6.86,
    },
    {
      img : "/images/pspMedicine/sinekod200.jpg",
      medicine: "სინეკოდი სიროფი 200მლ",
      price: 19.94,
      sale: 40,
      salePrice: 11.96,
    },
  ],
};
